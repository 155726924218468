.container {
  padding-bottom: 100px;
  font-family: "Rubik, sans-serif";
}

.header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1);
  padding: 15px 10px;
}

.headerTitle {
  margin-left: 20px;
  font-size: 25px;
  font-family: fantasy;
  align-self: center;
}

.pageList {
  display: flex;
  list-style-type: none;

  li {
    width: 10vw;
    height: 50px;

    &:hover {
      cursor: pointer;
      color: #5005f2;
    }
  }
}

.pageList div {
  display: flex;
}

.mainSquareImage {
  width: 60px;
  height: 60px;
}

.joinWaitListButton {
  height: 50px;
  width: 120px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  font-weight: bold;
  font-family: 'Circular-Loom';
  background: linear-gradient(90deg, rgba(186, 244, 250, 1) 0%, rgba(178, 175, 231, 1) 51%, rgba(143, 170, 228, 1) 100%);

  
  &:active {
    opacity: 0.7;
  }

  &:hover {
    cursor: pointer;
  }
}

.listButton {
  height: 50px;
  line-height: 50px;
  font-size: 25px;
  margin-left: auto;
  padding-right: 20px;

  &:hover {
    cursor: pointer;
  }
}

.navList {
  position: fixed;
  background-color: white;
  width: 100%;
  top: 0;
  padding-top: 30px;
  padding-left: 20px;
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1);

  .closeList {
    position: absolute;
    width: 20px;
    line-height: 10px;
    height: 20px;
    top: 10px;
    right: 35px;
    font-size: 45px;

    &:after {
      display: inline-block;
      content: "\00d7"; /* This will render the 'X' */
    }

    &:hover {
      cursor: pointer;
    }
  }

  div {
    height: 50px;
    width: 100%;
    padding: 20px;

    &:hover {
      cursor: pointer;
      color: #5005f2;
    }
  }
}

.introPic {
  width: 100%;
  @media (max-width: 481px) {
    height: 30vh;
  }
}

.introText {
  position: absolute;
  top: 150px;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2vw;
  z-index: -1;
  @media screen and (max-width: 600px) {
    font-size: 4.5vw;
  }
}

.joinWaitListButtonContainer {
  text-align: center;
  margin-top: 45px;

  button {
    width: 40%;
    margin-left: 2%;
  }
}

.leave_btn {
  cursor: pointer;
  background-color: #e7e7e7;
  color: black;
  width: fit-content;
  padding: 25px 50px;
  margin: 0 auto;
  margin-top: 40px;
}

.mainIntroText {
  text-align: center;
  font-size: 16px;
  color: gray;
  @media screen and (max-width: 650px) {
    margin-top: 30px;
  }
}