.container {
  text-align: center;
  padding: 50px;
}

.enterRoomBtn {
  background-color: #e7e7e7;
  color: black;
  text-align: center;
  width: 25%;
  margin: 0 auto;
  margin-top: 50px;
  height: 50px;
  line-height: 50px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.leaveBtn {
  background-color: #e7e7e7;
  color: black;
  width: 25%;
  margin: 0 auto;
  margin-top: 50px;
  height: 50px;
  line-height: 50px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.returnHomePageBtn {
  background-color: #e7e7e7;
  color: black;
  width: 25%;
  margin: 0 auto;
  margin-top: 50px;
  height: 50px;
  line-height: 50px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.selectLabel {
  margin-right: 20px;
}

.selectRound {
  /* styling */
  background-color: white;
  border: thin solid sandybrown;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) .5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;

  &:focus {
    outline:0;
  }
}