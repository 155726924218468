.canvas {
  border: 1px solid black;
}

.container {
  text-align: center;
  padding: 30px;
}

.leaveBtn {
  background-color: #e7e7e7;
  color: black;
  width: 25%;
  margin: 0 auto;
  height: 50px;
  margin-top: 20px;
  line-height: 50px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.videoArea {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  video {
    width: 35%;
  }
  @media screen and (max-width: 650px) {
    display: block;
    margin-top: 50px;
    video {
      width: 80%;
    }
  }
}