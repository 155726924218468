.message_box {
  height: 95vh;
  padding: 0 10%;
  background-color: antiquewhite;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  overflow-y: scroll;

  @media (max-width: 481px) {
    padding: 20px;
    padding-bottom: 35px;
  }
}

.text_message {
  max-width: 50vw;
  padding: 12px;
  border-radius: 5px;
  margin: 10px;
  border: 2px solid;
  border-radius: 12px;
  overflow-wrap: break-word;
}

.action_footer {
  height: 5vh;
  display: flex;
  border: 1px solid black;
  @media (max-width: 481px) {
    position: fixed;
    width: 100%;
    bottom: 0px;
    background-color: white;
  }
}

.leave_btn {
  flex: 1;
  border: antiquewhite;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
  align-self: center;
}

.send_btn {
  flex: 1;
  border: antiquewhite;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
  align-self: center;
}

.text_input {
  flex: 5;
  font-size: 18px;

  &:focus {
    outline: none;
  }
}

::-webkit-scrollbar {
  display: none;
}